<template>
  <div class="container">
    <h2 class="edit">Учасники проекту</h2>
    <ul class="block-list block-list--chat">
      <li>
        <div class="block-list__img"><img src="https://via.placeholder.com/158x158.png" alt=""></div>
        <div class="block-list__text">
          <h4>Петренко Катерина Василівна</h4><span>Менеджер по роботі з клієнтами</span><a class="icon icon-arrow-right" href="#"></a>
        </div>
      </li>
      <li>
        <div class="block-list__img"><img src="https://via.placeholder.com/158x158.png" alt=""></div>
        <div class="block-list__text">
          <h4>Оленчук Анатолій Олегович</h4><span>Менеджер документального супроводження</span><a class="icon icon-arrow-right" href="#"></a>
        </div>
      </li>
    </ul>
  </div>
</template>


<style lang="scss">
  @import '../../assets/chat.css';
</style>

<script>
export default {
  metaInfo(){
    return {
      title: this.$title('Чат')
    }
  },
}
</script>